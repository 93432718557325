import React, { forwardRef, useState } from 'react';
import classes from './CustomerData.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaTelegramPlane } from 'react-icons/fa';

const CustomerData = (props, ref) => {
  const [internalValidationNombre, setInternalValidationNombre] = useState(true);
  const [internalValidationCumpleanos, setInternalValidationCumpleanos] = useState(true);
  const [internalValidationEmail, setInternalValidationEmail] = useState(true);
  const [internalValidationTelefono, setInternalValidationTelefono] = useState(true);
  const [internalValidationComentarios, setInternalValidationComentarios] = useState(true);

  const [selectedCountryCode, setSelectedCountryCode] = useState('+52'); // Default to Mexico
  const [phoneNumber, setPhoneNumber] = useState('');

  /**
   * Helpers
   */
  const validateString = (stringState, validationState, internalValidationState, string, pattern) => {
    if (string === '') {
      stringState('No proporcionado');
      validationState(true);
      internalValidationState(true);
    } else {
      if (string.match(pattern)) {
        stringState(string);
        validationState(true);
        internalValidationState(true);
      } else {
        stringState('');
        validationState(false);
        internalValidationState(false);
      }
    }
  };

  const normalizeFecha = (inputValue) => {
    const dateObject = new Date(inputValue);
    if (!isNaN(dateObject.getTime())) {
      return dateObject.toISOString().split('T')[0];
    } else {
      return '';
    }
  };

  const handleChangeClienteCumpleanos = (event) => {
    const inputValue = event.target.value;
    const normalizedFecha = normalizeFecha(inputValue);
    console.log(normalizedFecha);

    validateString(
      props.setClienteCumpleanos,
      props.setValidationClienteCumpleanos,
      setInternalValidationCumpleanos,
      normalizedFecha,
      /^\d{4}-\d{2}-\d{2}$/
    );
  };

  const handleChangeClienteNombre = (event) => {
    const inputValue = event.target.value;

    if (inputValue.trim() === '') {
      alert('nombre vacio');
      setInternalValidationNombre(false);
      props.setValidationClienteNombre(false);
      props.setClienteNombre('');
      console.log('vacio nombre');
    } else {
      validateString(
        props.setClienteNombre,
        props.setValidationClienteNombre,
        setInternalValidationNombre,
        event.target.value,
        /^[\p{L} ,.'-]+$/u
      );
    }
  };

  const handleChangeClienteEmail = (event) => {
    validateString(
      props.setClienteEmail,
      props.setValidationClienteEmail,
      setInternalValidationEmail,
      event.target.value,
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    );
  };

  const handleChangeClienteTelefono = (event) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);

    const sanitizedPhoneNumber = inputValue.replace(/\D/g, '');

    const isValidPhoneNumber = sanitizedPhoneNumber.length === 10;

    if (isValidPhoneNumber) {
      // Internally set phone based on country selected
      let finalPhoneNumber = '';
      if (selectedCountryCode === '+52') {
        finalPhoneNumber = `521${sanitizedPhoneNumber}`;
      } else {
        finalPhoneNumber = `1${sanitizedPhoneNumber}`;
      }

      props.setClienteTelefono(finalPhoneNumber);
      setInternalValidationTelefono(true);
      props.setValidationClienteTelefono(true);
    } else {
      setInternalValidationTelefono(false);
      props.setValidationClienteTelefono(false);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
    setPhoneNumber(''); // Reset phone number field when the country changes
    setInternalValidationTelefono(true); // Reset validation
  };

  const handleChangeComentarios = (event) => {
    validateString(
      props.setClienteComentarios,
      props.setValidationClienteComentarios,
      setInternalValidationComentarios,
      event.target.value,
      /^[\p{L} ,.'-]+$/u
    );
  };

  return (
    <div id={props.inputName} className={classes.datosClienteWrapper} ref={ref}>
      <h1>{props.number}.</h1>
      <h1>{props.title.h1}</h1>
      <h3>{props.title.h3}</h3>

      {/* Nombre */}
      <Form.Group className="mb-3 col-10 col-sm-8" controlId="formBasicText">
        <Form.Control
          type="text"
          placeholder="Nombre"
          name="nombre"
          onChange={handleChangeClienteNombre}
          maxLength="24"
        />
        {internalValidationNombre === false && (
          <small className={classes.error}>El nombre que has escrito no es válido.</small>
        )}
      </Form.Group>

      {/* Cumpleaños */}
      <Form.Group className="mb-3 col-10 col-sm-8 date-picker-wrapper" controlId="formBasicBirthday">
        <Form.Control
          type="date"
          placeholder="Cumpleaños"
          name="cumpleanos"
          className={`date-input ${internalValidationCumpleanos ? 'has-value' : ''}`}
          onChange={handleChangeClienteCumpleanos}
        />
        <Form.Label className="date-placeholder">Cumpleaños</Form.Label>

        {internalValidationCumpleanos === false && (
          <small className={classes.error}>La fecha no es válida.</small>
        )}
      </Form.Group>

      {/* E-mail */}
      <Form.Group className="mb-3 col-10 col-sm-8" controlId="formBasicEmail">
        <Form.Control
          type="email"
          placeholder="Correo electrónico"
          name="email"
          onChange={handleChangeClienteEmail}
          maxLength="48"
        />
        {internalValidationEmail === false && (
          <small className={classes.error}>El correo electrónico que has escrito no es válido.</small>
        )}
      </Form.Group>

      {/* Teléfono con dropdown de código de país */}
      <Form.Group className="mb-3 col-10 col-sm-8" controlId="formBasicPhone">
      <Form.Label className='text-secondary'>Whatsapp / Teléfono</Form.Label>
        <div className="d-flex">
          <Form.Select onChange={handleCountryChange} value={selectedCountryCode} className="w-auto">
            <option value="+52">MX+52</option>
            <option value="+1">US/CAN+1</option>
          </Form.Select>
        
          <Form.Control
            type="tel"
            placeholder="Escribe tu número"
            name="telefono"
            value={phoneNumber}
            onChange={handleChangeClienteTelefono}
            maxLength="10"
          />
          
           
          
        </div>
       
        {internalValidationTelefono === false && <small className={classes.error}>El teléfono debe tener 10 dígitos.</small>}
      </Form.Group>

      {/* Comentarios */}
      <Form.Group className="col-10 col-sm-8" controlId="formComments">
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Comentarios"
          name="comentarios"
          onChange={handleChangeComentarios}
        />
        {internalValidationComentarios === false && (
          <small className={classes.error}>Verifique que el comentario no tiene caracteres inválidos.</small>
        )}
      </Form.Group>

      <small className="mt-1 col-10 col-sm-8 text-center">
        * Al poner tus datos aceptas recibir descuentos, promociones y noticias en tu correo o teléfono.
      </small>

      <Button variant="primary" size="lg" className="buttonSend" type="submit">
        <FaTelegramPlane /> Finalizar
      </Button>
    </div>
  );
};

export default forwardRef(CustomerData);
