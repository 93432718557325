import React, {forwardRef} from "react"
import classes from './SelectFormaPedido.module.css'
import { Form } from "react-bootstrap"
import Button from 'react-bootstrap/Button'

const SelectRazonCompra = (props, ref) => {

    return <div ref={ref} className={classes.questionWrapper}>
    
        <h1>{ props.number }.</h1>
        <h1 className="mb-5">{ props.title }</h1>

        <Form.Label>

            <div className={classes.optionsContainer}>

                <Button
                    className={ [classes.optionButton, classes.btnWhatsApp].join( ' ' ) }
                        variant={ 'sucursal' }
                        onClick={ (e) => {
                            props.setValue("Sucursal")
                            props.onClick(e)
                        }
                    }
                >
                    Sucursal
                </Button>

                <Button     
                    className={ [classes.optionButton, classes.btnFacebook].join(' ') }
                    variant={ 'domicilio' }
                        onClick={ (e) => {
                            props.setValue("Domicilio")
                            props.onClick(e)
                        }
                    }
                >
                    Domicilio
                </Button>


            </div>

        </Form.Label>

    </div>

}

export default forwardRef(SelectRazonCompra)